import { strToWad } from '@hailstonelabs/big-number-utils'
import { constants, utils } from 'ethers'
import { useMemo } from 'react'
import { useContract } from 'wagmi'
import { POOLS } from '../../constants/contract/pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { ROUTERS } from '../../constants/contract/router'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN, TOKENS } from '../../constants/contract/token'
import { Token } from '../../constants/contract/token/Token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useSwapContext } from '../../context/SwapContext'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { useWalletSelected } from '../../context/WalletSelectedContext'
import { useWeb3 } from '../../context/Web3Context'
import useApproval, { TokenApprovalState } from '../../hooks/useApproval'
import { getFilteredTokenMaps } from '../../utils/router'
import WButton, { Variant } from '../WButton'
import { SwapApprovalButton } from './SwapApprovalButton'
import { SwapButton } from './SwapButton'
import SwapStep from './SwapStep'

export const SwapBottomButtons = () => {
  const { account } = useWeb3()
  const {
    fromTokenSymbol,
    toTokenSymbol,
    fromTokenAmount,
    isQuoteRequestFailed,
    sourceChainId,
    isCrossChainSwap,
  } = useSwapContext()

  const { fromTokenAmountGtBalance, isEndCovRatioExceed } = useSwapInputContext()
  const isDisplayFromValueGtZero = strToWad(fromTokenAmount).gt(0)

  const { open: onOpenModalConnectWallet } = useWalletSelected()
  const { chainId, signer } = useWeb3()

  const routerWritableContract = useContract({
    ...ROUTERS[chainId]?.get(),
    signerOrProvider: signer,
  })

  const availabeTokenMaps: { [id in TokenSymbol]?: Token } = useMemo(
    () => getFilteredTokenMaps(chainId, true),
    [chainId]
  )

  const isFromTokenNativeToken = useMemo(() => {
    return fromTokenSymbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
  }, [chainId, fromTokenSymbol])

  const fromTokenAmountBN = fromTokenAmount
    ? utils.parseUnits(fromTokenAmount, TOKENS[chainId][fromTokenSymbol]?.decimals)
    : constants.Zero

  const spenderAddress = isCrossChainSwap
    ? POOLS[sourceChainId][PoolLabels.CROSS_CHAIN]?.address
    : routerWritableContract?.address
  const { approvalState, tryApproval } = useApproval(
    availabeTokenMaps[fromTokenSymbol]?.address ?? null,
    spenderAddress ?? null,
    fromTokenAmountBN,
    isFromTokenNativeToken
  )

  const errorMessage = useMemo(() => {
    const fromToken = TOKENS[chainId][fromTokenSymbol]
    let errorMsg = ''
    if (!!fromTokenAmount && !isDisplayFromValueGtZero) {
      errorMsg = 'Enter a non-zero amount'
    } else if (fromTokenAmountGtBalance && !!account) {
      errorMsg = `${fromToken?.displaySymbol} amount exceeds balance`
    } else if (isEndCovRatioExceed) {
      errorMsg = 'No swaps allowed because the coverage ratio cap has been reached'
    } else if (isQuoteRequestFailed) {
      errorMsg = 'Not enough liquidity for this trade'
    }
    return errorMsg
  }, [
    account,
    chainId,
    fromTokenAmount,
    fromTokenAmountGtBalance,
    fromTokenSymbol,
    isDisplayFromValueGtZero,
    isEndCovRatioExceed,
    isQuoteRequestFailed,
  ])

  if (!account) {
    return (
      <div className="mt-3 flex flex-col justify-between space-x-2">
        <WButton
          variant={Variant.GRADIENT}
          width="w-full"
          height="h-11"
          className={`swap-${fromTokenSymbol}-${toTokenSymbol}-connect-wallet`}
          onClick={onOpenModalConnectWallet}
        >
          CONNECT WALLET
        </WButton>
        <div className="-mb-2 mt-2 text-center text-red-600">{errorMessage}</div>
      </div>
    )
  } else {
    return (
      <div>
        <div
          id="swap-buttons"
          className="mt-3 flex w-full flex-col justify-center space-x-0 space-y-1 md:flex-row md:justify-between md:space-x-1 md:space-y-0"
        >
          {approvalState !== TokenApprovalState.APPROVED && (
            <SwapApprovalButton
              approvalState={approvalState}
              tryApproval={() =>
                tryApproval(fromTokenAmount, TOKENS[chainId][fromTokenSymbol]?.decimals)
              }

            />
          )}
          {approvalState === TokenApprovalState.APPROVED && (
            <SwapButton approvalState={approvalState} />
          )}
        </div>
        <div className="-mb-2 mt-2 text-center text-red-600">{errorMessage}</div>
        <SwapStep approvalState={approvalState} />
      </div>
    )
  }
}
