import React from 'react'
import BoostPage from '../components/BoostPage'
import BridgePage from '../components/BridgePage'
import GaugeVotingPage from '../components/GaugeVotingPage'
import PoolPage from '../components/PoolPage'
import SelfServiceBribePage from '../components/SelfServiceBribePage'
import SwapPage from '../components/SwapPage'
import { TncPage } from '../components/TncPage'
import TokenWithdrawalPage from '../components/TokenWithdrawalPage'
import WidgetWom from '../components/WidgetWom'
import { BoostFooterSvg, GaugeFooterSvg, PoolFooterSvg, SwapFooterSvg } from '../components/images'
import { SupportedChainId } from './web3/supportedChainId'

export enum RouteId {
  SWAP = 'SWAP',
  POOL = 'POOL',
  BOOST = 'BOOST',
  WIDGET = 'WIDGET',
  GAUGE_VOTING = 'GAUGE_VOTING',
  TOKEN_WITHDRAWAL = 'TOKEN_WITHDRAWAL',
  BRIDGE = 'BRIDGE',
  BRIBE = 'BRIBE',
  TNC = 'TNC',
  SABLE = 'USDS',
}

export type RouteType = {
  path: string
  name: string
  component: React.FC | null
  footerSvg: ({ fill }: { fill: string }) => JSX.Element
  visibleOnNavbar: boolean
  hideOnMobileNavbar?: boolean
  isExternalLink?: boolean
  children?: RouteType[]
}

export const routes: { [id in RouteId]: RouteType } = {
  [RouteId.SWAP]: {
    path: '/swap',
    name: 'Swap',
    component: SwapPage,
    footerSvg: SwapFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.POOL]: {
    path: '/pool',
    name: 'Pool',
    component: PoolPage,
    footerSvg: PoolFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.BOOST]: {
    path: '/boost',
    name: 'Boost',
    component: BoostPage,
    footerSvg: BoostFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.GAUGE_VOTING]: {
    path: '/gauge-voting',
    name: 'Gauge',
    component: GaugeVotingPage,
    footerSvg: GaugeFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.TOKEN_WITHDRAWAL]: {
    path: '/token-withdrawal',
    name: 'Token Withdrawal',
    component: TokenWithdrawalPage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.BRIDGE]: {
    path: '/bridge',
    name: '$WOM Bridge',
    component: BridgePage,
    footerSvg: () => <></>,
    visibleOnNavbar: true,
    hideOnMobileNavbar: true,
  },
  [RouteId.BRIBE]: {
    path: '/bribe',
    name: 'Bribe',
    component: SelfServiceBribePage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.TNC]: {
    path: '/terms',
    name: 'Terms And Conditions',
    component: TncPage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.SABLE]: {
    path: 'https://sable.finance/',
    name: 'SABLE',
    component: null,
    footerSvg: () => <></>,
    visibleOnNavbar: true,
    hideOnMobileNavbar: true,
    isExternalLink: true,
  },
  [RouteId.WIDGET]: {
    path: '/getWOM',
    name: 'Get $WOM',
    component: WidgetWom,
    footerSvg: SwapFooterSvg,
    hideOnMobileNavbar: true,
    visibleOnNavbar: true,
  },
}

export const devRoutes: { [id in RouteId]: RouteType } = {
  [RouteId.SWAP]: {
    path: '/dev/swap',
    name: 'Swap',
    component: SwapPage,
    footerSvg: SwapFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.POOL]: {
    path: '/dev/pool',
    name: 'Pool',
    component: PoolPage,
    footerSvg: PoolFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.BOOST]: {
    path: '/dev/boost',
    name: 'Boost',
    component: BoostPage,
    footerSvg: BoostFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.GAUGE_VOTING]: {
    path: '/dev/gauge-voting',
    name: 'Gauge',
    component: GaugeVotingPage,
    footerSvg: GaugeFooterSvg,
    visibleOnNavbar: true,
  },
  [RouteId.TOKEN_WITHDRAWAL]: {
    path: '/dev/token-withdrawal',
    name: 'Token Withdrawal',
    component: TokenWithdrawalPage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.BRIDGE]: {
    path: '/dev/bridge',
    name: 'Bridge',
    component: BridgePage,
    footerSvg: () => <></>,
    visibleOnNavbar: true,
    hideOnMobileNavbar: true,
  },
  [RouteId.BRIBE]: {
    path: '/dev/bribe',
    name: 'Bribe',
    component: SelfServiceBribePage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.TNC]: {
    path: '/dev/terms',
    name: 'Terms And Conditions',
    component: TncPage,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
  },
  [RouteId.SABLE]: {
    path: 'https://sable.finance/',
    name: 'USDS',
    component: null,
    footerSvg: () => <></>,
    visibleOnNavbar: false,
    isExternalLink: true,
  },
  [RouteId.WIDGET]: {
    path: '/getWOM',
    name: 'Get $WOM',
    component: WidgetWom,
    footerSvg: SwapFooterSvg,
    hideOnMobileNavbar: true,
    visibleOnNavbar: true,
  },
}

export const ROUTES_TO_BE_HIDDEN: { [key in RouteId]?: SupportedChainId[] } = {
  [RouteId.BOOST]: [
    SupportedChainId.SCROLL_SEPOLIA_TESTNET,
    SupportedChainId.SCROLL_MAINNET,
    SupportedChainId.SKALE_TESTNET,
    SupportedChainId.POLYGON_MAINNET,
    // SupportedChainId.ZKSYNC_MAINNET,
    // SupportedChainId.ZKSYNC_TESTNET,
  ],
  [RouteId.GAUGE_VOTING]: [
    SupportedChainId.SCROLL_SEPOLIA_TESTNET,
    SupportedChainId.SCROLL_MAINNET,
    SupportedChainId.SKALE_TESTNET,
    SupportedChainId.BASE,
    SupportedChainId.POLYGON_MAINNET,
    SupportedChainId.AVAX,
    SupportedChainId.OP_MAINNET,
    // SupportedChainId.ZKSYNC_MAINNET,
    // SupportedChainId.ZKSYNC_TESTNET,
  ],
  [RouteId.BRIDGE]: [
    // SupportedChainId.SCROLL_SEPOLIA_TESTNET,
    // SupportedChainId.SCROLL_MAINNET,
    SupportedChainId.SKALE_TESTNET,
    SupportedChainId.POLYGON_MAINNET,
    // SupportedChainId.ZKSYNC_TESTNET,
  ],
}

// nav
export const navList = ({
  isHiddenBribe,
  chainId,
}: {
  isHiddenBribe?: boolean
  chainId: SupportedChainId
}) => {
  const isDev =
    localStorage.getItem('isDev') !== null ? localStorage.getItem('isDev') === 'true' : false
  const routesObj = isDev ? devRoutes : routes
  let swapList = [
    !ROUTES_TO_BE_HIDDEN[RouteId.SWAP]?.includes(chainId) ? routesObj[RouteId.SWAP] : undefined,
    {
      path: 'https://trade.sable.finance/',
      name: 'Sable Perps',
      component: null,
      footerSvg: () => <></>,
      visibleOnNavbar: true,
      hideOnMobileNavbar: true,
      isExternalLink: true,
    },
    !ROUTES_TO_BE_HIDDEN[RouteId.BRIDGE]?.includes(chainId) ? routesObj[RouteId.BRIDGE] : undefined,
    routesObj[RouteId.WIDGET],
  ]

  let earnList = [
    !ROUTES_TO_BE_HIDDEN[RouteId.POOL]?.includes(chainId) ? routesObj[RouteId.POOL] : undefined,
    !ROUTES_TO_BE_HIDDEN[RouteId.BOOST]?.includes(chainId) ? routesObj[RouteId.BOOST] : undefined,
    !ROUTES_TO_BE_HIDDEN[RouteId.GAUGE_VOTING]?.includes(chainId)
      ? routesObj[RouteId.GAUGE_VOTING]
      : undefined,
    {
      path: 'https://app.sable.finance/',
      name: 'Sablecoin',
      component: null,
      footerSvg: () => <></>,
      visibleOnNavbar: true,
      hideOnMobileNavbar: true,
      isExternalLink: true,
    },
  ]

  swapList = swapList.filter((item) => !!item)
  earnList = earnList.filter((item) => !!item)
  if (!isHiddenBribe) {
    earnList.push(routesObj[RouteId.BRIBE])
  }
  const nav: RouteType[] = [
    {
      path: '',
      name: 'SWAP',
      component: null,
      footerSvg: () => <></>,
      visibleOnNavbar: false,
      children: swapList as RouteType[],
    },
    {
      path: '',
      name: 'EARN',
      component: null,
      footerSvg: () => <></>,
      visibleOnNavbar: false,
      children: earnList as RouteType[],
    },
    {
      path: 'https://wompets.com/',
      name: 'Wompets',
      component: null,
      footerSvg: () => <></>,
      visibleOnNavbar: true,
      hideOnMobileNavbar: true,
      isExternalLink: true,
    },
  ]

  return nav
}
